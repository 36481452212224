// src/utils/Menu.js
import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Menu = () => {
  const navigate = useNavigate();

  return (
    <AppBar position="static" style={{ backgroundColor: '#333' }}>
      <Toolbar>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          FaucetPool
        </Typography>
        <Button color="inherit" onClick={() => navigate('/')}>
          Início
        </Button>
        <Button color="inherit" onClick={() => navigate('/project-info')}>
          Informações do Projeto
        </Button>
        <Button color="inherit" onClick={() => navigate('/privacy-policy')}>
          Política de Privacidade
        </Button>
        <Button color="inherit" onClick={() => navigate('/terms-of-service')}>
          Termos de Serviço
        </Button>
        <Button color="inherit" onClick={() => navigate('/nft-01')}>
          Informações do NFTs
        </Button>
        <Button color="inherit" onClick={() => navigate('/dashboard')}>
          Dashboard
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Menu;