import React from 'react';
import logo from '../img/Logo_01.jpg'; // Substitua pelo caminho correto da sua logomarca
import '../OpeningPage.css'; // Utiliza os mesmos estilos da OpeningPage



const Nft_01 = () => {
  return (
    
    <div className="project-info">
      
      <img src={logo} alt="Logo" className="logo" />
      <h1 className="project-title">Informações do NFTs</h1>
      <p className="project-description">
        Este projeto é uma demonstração de como criar uma página informativa.
      </p>
      <p className="project-details">
        Aqui você pode adicionar mais detalhes sobre o projeto, como objetivos, tecnologias utilizadas, e muito mais.
      </p>
    </div>
  );
};

export default Nft_01;