import React from 'react';
import logo from './img/Logo_01.jpg'; // Substitua pelo caminho correto da sua logomarca
import './OpeningPage.css'; // Utiliza os mesmos estilos da OpeningPage



const PrivacyPolicy = () => {
  return (
    <div className="opening-page">
      
     
      <img src={logo} alt="Logo" className="logo" />
      <div className="content">
        <h1>Política de Privacidade</h1>
        <p>
          Sua privacidade é importante para nós. Esta Política de Privacidade explica como coletamos, usamos e protegemos suas informações pessoais ao utilizar o FaucetPool.
        </p>
        <h2>Coleta de Informações</h2>
        <p>
          Coletamos informações fornecidas por você durante o cadastro, como nome e endereço de e-mail, para oferecer uma experiência personalizada.
        </p>
        <h2>Uso de Informações</h2>
        <p>
          As informações coletadas são utilizadas para melhorar nossos serviços, fornecer suporte e comunicar atualizações relevantes.
        </p>
        <h2>Proteção de Dados</h2>
        <p>
          Implementamos medidas de segurança para proteger seus dados contra acesso não autorizado ou divulgação indevida.
        </p>
        {/* Adicione mais seções conforme necessário */}
      </div>
    </div>
  );
};

export default PrivacyPolicy;