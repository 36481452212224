import React, { useState } from 'react';
import { Container, Grid, Paper, Typography } from '@mui/material';
import logo from './img/Logo_01.jpg';
import { connectMetaMask } from "./utils/connectMetaMask";

const Dashboard = () => {
  const [connected, setConnected] = useState(false);
  const [hasToken, setHasToken] = useState(false);
  const [dashboardCards, setDashboardCards] = useState([]);

  const handleConnect = async () => {
    const success = await connectMetaMask();
    setConnected(success);
  
    if (success) {
      const accounts = await window.ethereum.request({ method: 'eth_accounts' });
      const userAddress = accounts[0];
      console.log(`MetaMask conectada: ${userAddress}`);
  
      // Chamar o backend para verificar o token
      try {
        const response = await fetch('/api/check-token', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userAddress }),
        });
  
        if (!response.ok) {
          throw new Error(`Erro na resposta do servidor: ${response.statusText}`);
        }
  
        const data = await response.json();
        console.log('Resposta do servidor:', data);
        setHasToken(data.hasToken);
  
        if (data.hasToken) {
          // Chamar o backend para criar um dashboard card
          const createCardResponse = await fetch('/api/create-dashboard-card', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ userAddress }),
          });
  
          const createCardData = await createCardResponse.json();
          if (createCardResponse.ok) {
            console.log('Dashboard card criado:', createCardData.card);
            setDashboardCards([...dashboardCards, createCardData.card]);
          } else {
            console.error('Erro ao criar o dashboard card:', createCardData.error);
          }
        }
      } catch (error) {
        console.error('Erro ao verificar o token:', error);
      }
    }
  };

  return (
    <Container maxWidth="lg" style={{ marginTop: '2rem' }}>
      <img src={logo} alt="Logo" className="logo" />
      <Typography variant="h4" gutterBottom>
        Dashboard
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
        <button onClick={handleConnect}>
          {connected ? "Conectado" : "Conectar MetaMask"}
        </button>
      </div>
      {connected && (
        <>
          <Typography variant="body1" style={{ marginTop: '1rem' }}>
            {hasToken ? "Você possui o token requerido." : "Você não possui o token requerido."}
          </Typography>
          <Grid container spacing={3} style={{ marginTop: '2rem' }}>
            {dashboardCards.map((card, index) => (
              <Grid item xs={12} md={6} lg={4} key={index}>
                <Paper style={{ padding: '1rem' }}>
                  <Typography variant="h6">{card.title}</Typography>
                  <Typography>{card.content}</Typography>
                  {card.imageUrl && <img src={card.imageUrl} alt={card.title} style={{ width: '100%', height: 'auto' }} />}
                </Paper>
              </Grid>
            ))}
          </Grid>
        </>
      )}
    </Container>
  );
};

export default Dashboard;