import React from 'react';
import logo from './img/Logo_01.jpg'; // Substitua pelo caminho correto da sua logomarca
import './OpeningPage.css'; // Utiliza os mesmos estilos da OpeningPage

const TermsOfService = () => {
  return (
    <div className="opening-page">
      
      <img src={logo} alt="Logo" className="logo" />
      <div className="content">
        <h1>Termos de Serviço</h1>
        <p>
          Bem-vindo ao FaucetPool. Ao utilizar nosso site, você concorda com os seguintes termos e condições.
        </p>
        <h2>Uso do Serviço</h2>
        <p>
          Você concorda em utilizar o FaucetPool apenas para fins legais e de acordo com as leis aplicáveis.
        </p>
        <h2>Cadastro</h2>
        <p>
          Para acessar determinados recursos, pode ser necessário criar uma conta fornecendo informações precisas e completas.
        </p>
        <h2>Propriedade Intelectual</h2>
        <p>
          Todo o conteúdo disponibilizado no FaucetPool é protegido por leis de propriedade intelectual e não pode ser reproduzido sem autorização.
        </p>
        {/* Adicione mais seções conforme necessário */}
      </div>
    </div>
  );
};

export default TermsOfService;