import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import './styles.css';
import OpeningPage from './OpeningPage';
import ProjectInfo from './ProjectInfo';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import Watermark from './utils/Watermark';
import Nft_01 from './nfts/Nft_01'; // Import the Nft_01 component
import Dashboard from './Dashboard'; // Import the Dashboard component

function App() {
  return (
    <div className="App">
      <Watermark />
      <Routes>
        <Route path="/" element={<OpeningPage />} />
        <Route path="/project-info" element={<ProjectInfo />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/nft-01" element={<Nft_01 />} /> 
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
    </div>
  );
}

export default App;