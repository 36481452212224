export async function connectMetaMask() {
    try {
        if (!window.ethereum) throw new Error("MetaMask não detectada.");

        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

        console.log("MetaMask conectada:", accounts[0]);
        return true;
    } catch (error) {
        console.error("Erro ao conectar MetaMask:", error);
        return false;
    }
}
