import React from 'react';
import './ProjectInfo.css';



const ProjectInfo = () => {
  return (
    
    <div className="project-info">
      
      
      <h1 className="project-title">Informações do Projeto</h1>
      <p className="project-description">
        Este projeto é uma demonstração de como criar uma página informativa.
      </p>
      <p className="project-details">
        Aqui você pode adicionar mais detalhes sobre o projeto, como objetivos, tecnologias utilizadas, e muito mais.
      </p>
    </div>
  );
};

export default ProjectInfo;