// src/utils/Watermark.js
import React from 'react';
import watermarkImage from '../img/under.png'; // Substitua pelo caminho correto da sua imagem de marca d'água
import './Watermark.css';


const Watermark = () => {
  return (
    <img src={watermarkImage} alt="Site em Manutenção" className="watermark" />
  );
};

export default Watermark;