import React, { useEffect, useState } from 'react';
import Menu from './utils/Menu';
import { useNavigate } from 'react-router-dom';
import { calculateArea } from './utils/calculateArea';
import logo from './img/Logo_01.jpg'; // Substitua pelo caminho da sua logomarca
import image1 from './img/walletPool_01.png'; // Substitua pelo caminho da sua imagem 1
import image2 from './img/walletPool_02.png'; // Substitua pelo caminho da sua imagem 2
import image3 from './img/walletPool_03.png'; // Substitua pelo caminho da sua imagem 3
import sectionImage1 from './img/sectionImage1.png'; // Substitua pelo caminho da sua imagem da seção 1
import sectionImage2 from './img/sectionImage2.png'; // Substitua pelo caminho da sua imagem da seção 2
import './OpeningPage.css';


const OpeningPage = () => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0, area: 0 });
  const [currentImage, setCurrentImage] = useState(0);
  const [fade, setFade] = useState(true);
  const images = [image1, image2, image3];
  const texts = ["texto um...", "texto dois...", "texto tres..."];
  const navigate = useNavigate();

  useEffect(() => {
    const updateDimensions = () => {
      const { width, height, area } = calculateArea();
      setDimensions({ width, height, area });
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentImage((prevImage) => (prevImage + 1) % images.length);
        setFade(true);
      }, 2000); // Tempo de transição de 2 segundos
    }, 5000); // Intervalo de 3 segundos + 2 segundos de transição

    return () => clearInterval(interval);
  }, [images.length]);

  const handleSectionImage1Click = () => {
    navigate('/project-info');
  };

  return (
    <div className="opening-page">
      <Menu />
      
      <img src={logo} alt="Logo" className="logo" />
      <div className="content">
        <h1 className="welcome-text">Bem-vindo ao Meu Projeto</h1>
        <div className={`image-container ${fade ? 'fade-in' : 'fade-out'}`}>
          <img src={images[currentImage]} alt="Imagem" className="image" style={{ height: dimensions.height * 0.6 }} />
          <p className="image-text">{texts[currentImage]}</p>
        </div>
        <div className="dimensions-info">
          <p>Largura: {dimensions.width}px</p>
          <p>Altura: {dimensions.height}px</p>
          <p>Área: {dimensions.area}px²</p>
        </div>
      </div>

      <div className="section" style={{ height: dimensions.height, width: dimensions.width }} onClick={handleSectionImage1Click}>
        <img src={sectionImage1} alt="Seção 1" className="section-image" />
        <div className="section-text">
          <h2>Texto Principal 1</h2>
          <p>Descrição da seção 1...</p>
        </div>
      </div>

      <div className="section" style={{ height: dimensions.height, width: dimensions.width }}>
        <img src={sectionImage2} alt="Seção 2" className="section-image" />
        <div className="section-text">
          <h2>Texto Principal 2</h2>
          <p>Descrição da seção 2...</p>
        </div>
      </div>
    </div>
  );
};

export default OpeningPage;